<template>
  <div>
    <div>
      <!-- <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/yeguang.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">成为会员</div>
        </div>
      </div> -->
      <div class="inputFrom"
        v-if="verifyshow">
        <div>头像</div>
        <div class="radio"
          style="border:none;">
          <van-uploader v-model="ImageList"
            multiple
            capture="camera"
            class="imgs"
            :max-count="1"
            :after-read="afterRead"
            :before-delete="jkDelete"
            :deletable="true" />

        </div>
        <div>社区</div>
        <div>
          <input v-model="idList.OCodeName"
            type="text"
            @click="idListOrg = true"
            readonly="readonly"
            placeholder="请选择社区" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ocodelist"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </div>
        <div>网格</div>
        <div>
          <input v-model="idList.GName"
            type="text"
            @click="idListGId = true"
            readonly="readonly"
            placeholder="请选择您所在网格" />
          <van-popup v-model="idListGId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="gidlist"
              value-key="Title"
              @cancel="idListGId = false"
              @confirm="onidListGId">
            </van-picker>
          </van-popup>
        </div>
        <div>小区</div>
        <div>
          <input v-model="idList.RIdTitle"
            type="text"
            @click="idListRId = true"
            readonly="readonly"
            placeholder="请选择您所住小区" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-search v-model="rIdTitle"
              show-action
              label="小区"
              placeholder="请输入小区名称"
              @search="onSearch">
              <template #action>
                <div @click="onSearchs">搜索</div>
              </template>
            </van-search>
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </div>

        <div>楼栋</div>
        <div>
          <input v-model="idList.BIdTitle"
            type="text"
            @click="idListBId = true"
            readonly="readonly"
            placeholder="请选择您所住楼栋" />
          <van-popup v-model="idListBId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="BIdlist"
              value-key="Title"
              @cancel="idListBId = false"
              @confirm="onidListBId">
            </van-picker>
          </van-popup>
        </div>
        <div>单元</div>
        <div>
          <input v-model="idList.BUIdTitle"
            type="text"
            @click="idListBUId = true"
            readonly="readonly"
            placeholder="请选择您所住单元" />
          <van-popup v-model="idListBUId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="BUIdlist"
              value-key="Title"
              @cancel="idListBUId = false"
              @confirm="onidListBUId">
            </van-picker>
          </van-popup>
        </div>
        <div>楼层</div>
        <div>
          <input v-model="idList.BFIdTitle"
            type="text"
            @click="idListBFId = true"
            readonly="readonly"
            placeholder="请选择您所住楼层" />
          <van-popup v-model="idListBFId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="BFIdlist"
              value-key="FloorNo"
              @cancel="idListBFId = false"
              @confirm="onidListBFId">
            </van-picker>
          </van-popup>
        </div>
        <div>房号</div>
        <div>
          <input v-model="idList.BRIdTitle"
            type="text"
            @click="idListBRId = true"
            readonly="readonly"
            placeholder="请选择您所住房号" />
          <van-popup v-model="idListBRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="BRIdlist"
              value-key="RoomNO"
              @cancel="idListBRId = false"
              @confirm="onidListBRId">
            </van-picker>
          </van-popup>
        </div>
        <div>家庭</div>
        <div>
          <input v-model="idList.FamilyTitle"
            type="text"
            @click="idFamilyList = true"
            readonly="readonly"
            placeholder="请选择家庭" />
          <van-popup v-model="idFamilyList"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="FamilyList"
              value-key="Householder"
              @cancel="idFamilyList = false"
              @confirm="onFamilyList">
            </van-picker>
          </van-popup>
        </div>
        <div>姓名</div>
        <div>
          <input v-model="idList.Name"
            placeholder="请输入您的姓名" />
        </div>
        <div>身份证号</div>
        <div>
          <input v-model="idList.IdCard"
            placeholder="请输入身份证号" />
        </div>
        <div>手机号</div>
        <div>
          <input v-model="idList.Mobile"
            placeholder="请输入手机号" />
        </div>
        <div>地址</div>
        <div>
          <textarea rows="3"
            v-model="idList.Address"
            placeholder="请输入地址" />
          </div>
        <div>座右铭</div>
        <div>
          <input v-model="idList.Motto"
            placeholder="请输入座右铭" />
        </div>
        <div>专业</div>
        <div>
          <input v-model="idList.Major"
            placeholder="请输入专业" />
        </div>
        <div>兴趣特长</div>
        <div>
          <input v-model="idList.Interest"
            placeholder="请输入兴趣特长" />
        </div>
        <div>是否党员</div>
        <div>
          <van-radio-group v-model="idList.PartyMember"
              direction="horizontal" class="radio">
              <van-radio name="0">否</van-radio>
              <van-radio name="1">是</van-radio>
          </van-radio-group>
        </div>
        <div>志愿者</div>
        <div>
         <van-radio-group v-model="idList.Volunteer"
              direction="horizontal" class="radio">
              <van-radio name="0">否</van-radio>
              <van-radio name="1">是</van-radio>
            </van-radio-group>
        </div>
      </div>
      <div class="buttons"
        v-if="verifyshow">
        <!-- <van-button style="border: 1px solid #e6e6e6"
          type="default"
          @click="fanhui">返回</van-button> -->
        <van-button type="info" style="width:80%;"
          @click="huiyuan">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WeGetGridList,
  WeMyMemberInfo,
  GetOrgansNullToken,
  WxSaveMember,
  WeGetRegionListOfGrid,
  WeGetBuildList,
  WeGetBuildUnitList,
  WeGetBuildFloorList,
  WeGetBuildRoomList,
  WeGetBuildFamilyList,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      gidlist: [],
      NativeCantonCodedata: [],
      ocodelist: [],
      ImageList: [{ url: '' }],
      idList: {
        OrgCode: "",
        Name: "", //姓名、
        IdCard: "", //身份证号、
        GName: "", //网格名称
        GId: "", //网格ID、
        Address: "", //通讯地址、
        Mobile: "", //手机号码、
        PartyMember: "", //否党员
        Volunteer: "", //志愿者
        Pwd: "", //密码、
        RIdTitle: "",// 小区
        RId: "",
        BIdTitle: "",// 楼栋
        BId: "",
        BUIdTitle: "",// 单元
        BUId: "",
        BFIdTitle: "",// 楼层
        BFId: "",
        BRIdTitle: "",// 房号
        BRId: "",
        FamilyTitle: "",// 家庭
        BFaId: "",
      },
      rIdTitle: '',
      idListGId: false,
      addshow: false,
      verifyshow: true,
      LiveCity: false,
      Political: false,
      OCodeshow: false,
      idListOrg: false,
      mumberXq: "",
      ridlist: [],//小区列表
      idListRId: false,
      BIdlist: [],//楼栋列表
      idListBId: false,
      BUIdlist: [],//单元列表
      idListBUId: false,
      BFIdlist: [],//楼层列表
      idListBFId: false,
      BRIdlist: [],//房号列表
      idListBRId: false,
      FamilyList: [],//家庭列表
      idFamilyList: false,
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.MemberInfo();
    this.gitocodelist();
  },
  methods: {
    // 获取会员详情
    // getOpenId()
    MemberInfo () {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == '0') {
          // 头像
          if (res.data.data.HeadImg != '') {
            this.ImageList[0].url = res.data.data.HeadImg
            this.idList.HeadImg = res.data.data.HeadImg
          } else {
            this.ImageList = [{ url: '' }]
          }
          // 姓名
          this.idList.Name = res.data.data.Name
          // 座右铭
          this.idList.Motto = res.data.data.Motto
          // 身份证号
          this.idList.IdCard = res.data.data.IdCard
          // 手机
          this.idList.Mobile = res.data.data.Mobile
          // 通讯地址
          this.idList.Address = res.data.data.Address
          // 专业
          this.idList.Major = res.data.data.Major
          // 兴趣特长
          this.idList.Interest = res.data.data.Interest
          // 支付密码
          this.idList.PayPwd = res.data.data.PayPwd
          // 社区编码
          this.idList.OrgCode = res.data.data.OrgCode
          // 社区名称
          this.idList.OCodeName = res.data.data.OrgName
          // 网格名称
          this.idList.GName = res.data.data.GName
          // 网格ID
          this.idList.GId = res.data.data.GId
          // 党员
          this.idList.PartyMember = res.data.data.PartyMember + "";
          // 志愿者
          this.idList.Volunteer = res.data.data.Volunteer + "";
          if (this.idList.OrgCode != '') {
            this.getGridList(this.idList.OrgCode);
          }
          // 获取小区列表
          if (res.data.data.RId != '' && res.data.data.RId != null) {
            this.idList.RId = res.data.data.RId;
            this.idList.RIdTitle = res.data.data.RName;
            this.getRegionForGridList(res.data.data.GId);
          }
          // 获取楼栋列表
          if (res.data.data.BId != '' && res.data.data.BId != null) {
            this.idList.BId = res.data.data.BId;
            this.idList.BIdTitle = res.data.data.BName;
            this.BuildList(res.data.data.RId);
          }
          // 获取单元列表
          if (res.data.data.BUId != '' && res.data.data.BUId != null) {
            this.idList.BUId = res.data.data.BUId;
            this.idList.BUIdTitle = res.data.data.BUName;
            this.BuildUnitList(res.data.data.BId);
          }
          // 获取楼层列表
          if (res.data.data.BFId != '' && res.data.data.BFId != null) {
            this.idList.BFId = res.data.data.BFId;
            this.idList.BFIdTitle = res.data.data.BFNo;
            this.BuildFloorList(res.data.data.BUId);
          }
          // 获取房号列表
          if (res.data.data.BRId != '' && res.data.data.BRId != null) {
            this.idList.BRId = res.data.data.BRId;
            this.idList.BRIdTitle = res.data.data.BRNo;
            this.BuildRoomList(res.data.data.BFId);
            this.getBuildFamilyList();
          }
          // 获取家庭列表
          if (res.data.data.BFaId != '' && res.data.data.BFaId != null) {
            this.idList.BFaId = res.data.data.BFaId;
            this.idList.FamilyTitle = res.data.data.Householder;
            this.getBuildFamilyList();
          }
        }
      })
    },
    //上传照片
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.idList.HeadImg = res.data.picurl
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除照片
    jkDelete (file) {
      this.ImageList = []
      this.idList.HeadImg = ""
    },
    // 提交
    huiyuan: function () {
      this.idList.OpenID = getOpenId();
      console.log(getOpenId());
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      if (this.idList.OrgCode == "") {
        Toast.fail("请选择社区");
        return false;
      }
      if (this.idList.GName == "") {
        Toast.fail("请选择网格");
        return false;
      }
      if (this.idList.RIdTitle == "") {
        Toast.fail("请选择小区");
        return false;
      }
      if (this.idList.BIdTitle == "") {
        Toast.fail("请选择楼栋");
        return false;
      }
      if (this.idList.BUIdTitle == "") {
        Toast.fail("请选择单元");
        return false;
      }
      if (this.idList.BFIdTitle == "") {
        Toast.fail("请选择楼层");
        return false;
      }
      if (this.idList.BRIdTitle == "") {
        Toast.fail("请选择房号");
        return false;
      }
      if (this.idList.FamilyTitle == "") {
        Toast.fail("请选择家庭");
        return false;
      }

      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      if (reg.test(this.idList.IdCard) == false) {
        Toast.fail("请输入正确的身份证号");
        return false;
      }
      if (this.idList.Mobile == "") {
        Toast.fail("请输入手机号");
        return false;
      }
      if (this.idList.Address == "") {
        Toast.fail("请输入通讯地址");
        return false;
      }
      this.idList.OpenID = getOpenId();
      WxSaveMember(this.idList).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {

            // if (window.__wxjs_environment === "miniprogram") {
            //   window.wx.miniProgram.reLaunch({
            //     url: "/pages/index/member/index",
            //   });
            // } else {
            //   alert("请在小程序中打开");
            // }
            this.$router.go(-1)
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    fanhui: function () {
      window.wx.miniProgram.reLaunch({
        url: "/pages/index/index",
      });
    },
    gitocodelist () {
      GetOrgansNullToken({
        level: 4,
        kind: 1,
      }).then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    ridpush: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 选择社区
    onidListOrg (val) {
      this.gidlist = []
      // 网格
      this.idList.GName = ''
      this.idList.GId = ''
      this.gidlist = []
      // 小区
      this.ridlist = []
      this.idList.RIdTitle = ''
      this.idList.RId = ''
      // 楼栋
      this.BIdlist = []
      this.idList.BIdTitle = ''
      this.idList.BId = ''
      // 单元
      this.BUIdlist = []
      this.idList.BUIdTitle = ''
      this.idList.BUId = ''
      // 楼层
      this.BFIdlist = []
      this.idList.BFIdTitle = ''
      this.idList.BFId = ''
      // 房号
      this.BRIdlist = []
      this.idList.BRIdTitle = ''
      this.idList.BRId = ''
      // 家庭 
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''


      this.idList.OrgCode = val.organcode;
      this.idList.OCodeName = val.organname;
      this.idListOrg = false;
      this.getGridList(val.organcode);
    },

    // 网格选择器
    onidListGId (val) {
      this.idList.GId = val.GId;
      this.idList.GName = val.Title;
      // 小区
      this.ridlist = []
      this.idList.RIdTitle = ''
      this.idList.RId = ''
      // 楼栋
      this.BIdlist = []
      this.idList.BIdTitle = ''
      this.idList.BId = ''
      // 单元
      this.BUIdlist = []
      this.idList.BUIdTitle = ''
      this.idList.BUId = ''
      // 楼层
      this.BFIdlist = []
      this.idList.BFIdTitle = ''
      this.idList.BFId = ''
      // 房号
      this.BRIdlist = []
      this.idList.BRIdTitle = ''
      this.idList.BRId = ''
      // 家庭
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''


      this.idListGId = false;
      this.getRegionForGridList()
    },
    // 获取小区列表
    getRegionForGridList () {

      WeGetRegionListOfGrid({
        gId: this.idList.GId,
        title: this.rName
      }).then((res) => {
        if (res.data.code == 0) {
          this.ridlist = res.data.data
        }
      })
    },
    // 搜索小区
    onSearch (val) {
      this.rName = val
      this.getRegionForGridList()
    },
    onSearchs () {
      this.rName = this.rIdTitle
      this.getRegionForGridList()
    },
    // 小区确认选中
    onidListRId (val) {
      console.log(val);
      this.idList.RId = val.RId;
      this.idList.RIdTitle = val.Title;

      // 楼栋
      this.BIdlist = []
      this.idList.BIdTitle = ''
      this.idList.BId = ''
      // 单元
      this.BUIdlist = []
      this.idList.BUIdTitle = ''
      this.idList.BUId = ''
      // 楼层
      this.BFIdlist = []
      this.idList.BFIdTitle = ''
      this.idList.BFId = ''
      // 房号
      this.BRIdlist = []
      this.idList.BRIdTitle = ''
      this.idList.BRId = ''
      // 家庭
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''


      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 楼栋列表
    BuildList: function (val) {
      WeGetBuildList({ rId: val }).then((res) => {
        this.BIdlist = res.data.data;
      });
    },
    // 楼栋选择器
    onidListBId (val) {
      this.idList.BId = val.BId;
      this.idList.BIdTitle = val.Title;
      // 单元
      this.BUIdlist = []
      this.idList.BUIdTitle = ''
      this.idList.BUId = ''
      // 楼层
      this.BFIdlist = []
      this.idList.BFIdTitle = ''
      this.idList.BFId = ''
      // 房号
      this.BRIdlist = []
      this.idList.BRIdTitle = ''
      this.idList.BRId = ''
      // 家庭
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''

      this.idListBId = false;
      this.BuildUnitList(val.BId);
    },
    // 单元列表
    BuildUnitList: function (val) {
      WeGetBuildUnitList({ bId: val }).then((res) => {
        this.BUIdlist = res.data.data;
      });
    },
    // 单元选择器
    onidListBUId (val) {
      this.idList.BUId = val.BUId;
      this.idList.BUIdTitle = val.Title;
      // 楼层
      this.BFIdlist = []
      this.idList.BFIdTitle = ''
      this.idList.BFId = ''
      // 房号
      this.BRIdlist = []
      this.idList.BRIdTitle = ''
      this.idList.BRId = ''
      // 家庭
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''

      this.idListBUId = false;
      this.BuildFloorList(val.BUId);
    },
    // 楼层列表
    BuildFloorList: function (val) {
      WeGetBuildFloorList({ bId: this.idList.BId, buId: val }).then((res) => {
        this.BFIdlist = res.data.data;
      });
    },
    // 楼层选择器
    onidListBFId (val) {
      this.idList.BFId = val.BFId;
      this.idList.BFIdTitle = val.FloorNo;
      // 房号
      this.BRIdlist = []
      this.idList.BRIdTitle = ''
      this.idList.BRId = ''
      // 家庭
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''
      this.idListBFId = false;
      this.BuildRoomList(val.BFId);
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.BUId,
        bfId: val,
      }).then((res) => {
        // console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    // 房屋选择器
    onidListBRId (val) {
      this.idList.BRId = val.BRId;
      this.idList.BRIdTitle = val.RoomNO;

      // 家庭
      this.FamilyList = []
      this.idList.BFaId = ''
      this.idList.FamilyTitle = ''
      this.idListBRId = false;
      this.getBuildFamilyList()
    },
    // 获取家庭信息
    getBuildFamilyList () {
      WeGetBuildFamilyList({
        bId: this.idList.BId,
        buId: this.idList.BUId,
        bfId: this.idList.BFId,
        brId: this.idList.BRId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.FamilyList = res.data.data
        }
      })
    },
    // 选中家庭
    onFamilyList (val) {
      this.idList.BFaId = val.BFaId;
      this.idList.FamilyTitle = val.Householder;
      this.idFamilyList = false
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.idList.OCodeName = val.selectedOptions[0].OrganName;
      this.idList.OCode = val.value;
      // this.ridpush(val.value);
      this.getGridList(val.value);
      this.OCodeshow = false;
    },
    // 网格
    getGridList: function (row) {
      WeGetGridList({ organCode: row }).then((res) => {
        this.gidlist = res.data.data;
      });
    },
    // 地址
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value);
      this.idList.NativeCantonCode = RegCity[0];
      this.idList.NativeCantonName = RegCity[1];
      this.LiveCity = false;
    },
    onPolitical (val) {
      this.idList.PoliticalCode = val.Coding;
      this.idList.PoliticalName = val.Name;
      this.Political = false;
    },
    addressfrom (value) {
      // 地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
    // 按钮
    dengji () {
      if (this.idList.RIdTitle == undefined) {
        Toast.fail("请选择所住小区");
        return false;
      }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      this.addshow = true;
      this.verifyshow = false;
    },
    pageshow () {
      this.addshow = false;
      this.verifyshow = true;
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.inputFrom textarea {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.inputFrom .radio {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>